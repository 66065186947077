<template>
  <div>
    <!-- 应用库 -->
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleLoad"
      class="tabs"
    >
      <el-tab-pane :label="$t('AppManage.ApplicationLibrary.AppLibAndroid')" name="1">
        <AndroidApp :key="timer1"></AndroidApp>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.ApplicationLibrary.AppLibIOS')" name="2">
        <IosApp :key="timer2"></IosApp>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Nav from "@/components/hearder";
import pagination from "@/components/pagination/page.vue";
import Eldialog from "@/components/elDialog/index.vue";
import dialogInfo from "@/components/promptMessage/index";
import AndroidApp from "./components/AppLibAndroid.vue";
import IosApp from "./components/AppLibIOS.vue";
export default {
  components: { Nav, pagination, Eldialog, dialogInfo, AndroidApp, IosApp },
  data() {
    return {
      timer1: "",
      timer2: "",
      activeName: "1",
    };
  },
  methods: {
    handleLoad(data) {
      let name = data.name;
      if (name == 1) {
        this.timer1 = new Date().getTime();
      } else if (name == 2) {
        this.timer2 = new Date().getTime();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item:hover{
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
</style>