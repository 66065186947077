<template>
  <div>
    <!-- Android应用 -->
    <div class="title" v-show="show">
      <!-- 查询 -->
      <el-row :gutter="3" style="padding: 0 0 0 10px">
        <el-col :span="3">
          <el-input
            size="small"
            :placeholder="$t('AppManage.ApplicationLibrary.Placeholder')"
            v-model.trim="keywords"
            @keyup.enter.native="search"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" style="height: 31px" size="small" @click="search">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <div class="btn">
        <el-button-group>
          <el-button type="primary" size="small" @click="addNew">{{
            $t("public.New")
          }}</el-button>
          <el-button
            style="
              border-radius: 0px 3px 3px 0px;
              margin: 0px 0px 0px 0px;
              margin-left: 1px;
            "
            @click="delMuch(delarr)"
            :type="typeInfo"
            :disabled="!hasSeleted"
            size="small"
            >{{ $t("public.Delete") }}</el-button
          >
        </el-button-group>
      </div>
      <el-row :gutter="16" class="mt16">
        <el-table
          size="mini"
          :row-key="getRowKeys"
          stripe
          ref="multipleTable"
          :data="tableData"
          class="sort_table"
          tooltip-effect="dark"
          style="width: 99%; margin-top: 5px; margin-left: 18px; padding-right: 20px"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }"
          @sort-change="sort"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            header-align="center"
            align="center"
            type="selection"
            width="60"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('public.ApplicationName')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="identifier"
            :label="$t('public.ApplicationID')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="versionName"
            :label="$t('public.Version')"
            width="180"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="lastUpdateTime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template v-slot="{ row }">
              {{ row.lastUpdateTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <img
                @click="DevDetail(row)"
                src="@/assets/icon_see.png"
                style="width: 15px; height: 15px; cursor: pointer"
                :title="$t('AppManage.ApplicationLibrary.Installation')"
              />
              <img
                @click="edit(row)"
                src="@/assets/icon_write.png"
                alt=""
                style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
                :title="$t('public.Editor')"
              />
              <img
                @click="del(row.id)"
                src="@/assets/icon_del.png"
                alt=""
                style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
                :title="$t('public.Delete')"
              />
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-left: 15px; margin-right: 20px">
          <pagination
            :page="page.currentPage"
            :limit="page.pageSize"
            :total="page.total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </div>
      </el-row>
    </div>
    <!-- 新增编辑的框 -->
    <div v-show="!show" style="margin-left: 60px; margin-top: 15px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('public.ApplicationName') + '：'" prop="name">
          <el-input
            size="small"
            style="width: 250px"
            v-model="ruleForm.name"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.ApplicationID') + '：'" prop="id">
          <el-input size="small" v-model="ruleForm.id" style="width: 250px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.Version') + '：'" prop="version">
          <el-input
            size="small"
            v-model="ruleForm.version"
            style="width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" size="mini">{{
            $t("public.Save")
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 安装设备的弹框 -->
    <Eldialog
      :num="'470px'"
      :width="'900px'"
      :title="
        appname +
        '\xa0' +
        appversion +
        '\xa0' +
        $t('AppManage.ApplicationLibrary.Installation')
      "
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
    >
      <el-input
        v-model="input"
        size="small"
        style="width: 200px; margin-bottom: 10px"
        :placeholder="$t('AppManage.ApplicationLibrary.DeviceNamePlaceholder')"
        @keyup.enter.native="searchDev"
      >
        <i
          style="cursor: pointer"
          class="el-icon-search el-input__icon"
          slot="suffix"
          @click="searchDev"
        >
        </i>
      </el-input>
      <el-table
        size="mini"
        stripe
        ref="multipleTable"
        :data="gridData"
        class="sort_table"
        tooltip-effect="dark"
        style="width: 99%"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          prop="deviceName"
          :label="$t('public.DeviceName')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="userInfo.userName"
          :label="$t('public.Username')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="userInfo.loginName"
          :label="$t('public.Name')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="groups.groupFullName"
          :label="$t('public.Section')"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <span v-if="row.groups.groupFullName">
              {{ row.groups.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :page="currentPage"
        :limit="pageSize"
        :total="record"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists"
      />
    </Eldialog>
    <!-- 保存成功的弹框 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import Eldialog from "@/components/elDialog"; //详情弹框
import naviGation from "@/components/hearder/index"; //头部组件

import pagination from "@/components/pagination/page.vue"; //分页
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: {
    Eldialog,
    naviGation,
    pagination,
    dialogInfo,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      id: "",
      show: true, //显示隐藏
      page: {
        total: 0, //总条数
        pageSize: 10, //每页条数,  默认10条
        currentPage: 1,
      },
      pageSize: 10, //每页条数,  默认10条
      currentPage: 1,
      input: "", //安装设备弹框的查询
      sorts: "lastUpdateTime desc",
      action: "", //新增或编辑判断
      delarr: [], //存放删除的数据
      record: 0, //弹框总数记录
      appname: "",
      ruleForm: {
        id: "",
        name: "",
        version: "",
      },
      gridData: [], //弹框数组
      keywords: "",
      deviceId: "",
      titleInfo: "",
      tableData: [],
      orderRule: "DESC",
      appversion: "",
      createTime: "",
      orderColume: "lastUpdateTime",
      deviceCount: 0,
      visibaelFlag: false, //提示消息默认--关闭
      lastUpdateTime: "",
      statusVisible: false, //关联应用弹框默认不显示
      groupFullNameShowByIndex: "", //用户部门截取的下标
      rules: {
        name: [
          {
            min: 1,
            max: 20,
            message: this.$t("AppManage.ApplicationLibrary.Rules.NameLength"),
            trigger: "blur",
          },
          {
            required: true,
            message: this.$t("AppManage.ApplicationLibrary.Rules.Name"),
            trigger: "blur",
          },
        ],
        id: [
          {
            required: true,
            message: this.$t("AppManage.ApplicationLibrary.Rules.Id"),
            trigger: "blur",
          },
        ],
        version: [
          {
            required: true,
            message: this.$t("AppManage.ApplicationLibrary.Rules.Version"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    this.getDatalist();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    //   列表
    async getDatalist() {
      var parma = {
        searchName: this.keywords,
        osType: 20,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };

      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    // 查询
    async search() {
      if (this.keywords.length > 0) {
        this.page.currentPage = 1;
      }
      var parma = {
        osType: 20,
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        searchName: this.keywords,
        orderColume: this.orderColume,
        orderRule: this.orderRule,
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        parma,
        true
      );
      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    // 新增
    addNew() {
      this.show = false;
      this.id = "";
      this.ruleForm = {};
    },
    // 编辑回显
    async edit(val) {
      this.id = val.id;
      this.show = false;
      this.ruleForm.id = val.identifier;
      this.ruleForm.name = val.name;
      this.ruleForm.version = val.versionName;
    },
    // 新增 编辑 的非空校验
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.id == "") {
            this.addSave();
          } else {
            this.editSave();
          }
        } else {
          console.log("error");
          return false;
        }
      });
    },
    // 新增接口
    async addSave() {
      var parma = {
        ids: [],
        identifier: this.ruleForm.id,
        name: this.ruleForm.name,
        osType: "20",
        versionName: this.ruleForm.version,
      };
      const res = await this.$axios.post("/httpServe/android/appLib/save", parma, true);
      if (res.errorCode == "25881") {
        this.$message({
          message: this.$t("AppManage.ApplicationLibrary.Error1"),
          type: "error",
          offset: 100,
        });
        return false;
      } else if (res.data == 0) {
        this.$message({
          message: this.$t("AppManage.ApplicationLibrary.Error2"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.show = true;
        this.keywords = "";
        this.getDatalist();
        this.ruleForm.id = "";
        this.ruleForm.name = "";
        this.ruleForm.version = "";
      }
    },
    // 编辑接口
    async editSave() {
      var parma = {
        ids: [this.id],
        identifier: this.ruleForm.id,
        name: this.ruleForm.name,
        osType: 20,
        versionName: this.ruleForm.version,
      };
      const res = await this.$axios.post("/httpServe/android/appLib/update", parma, true);
      if (res.errorCode == "25881") {
        this.$message({
          message: this.$t("AppManage.ApplicationLibrary.Error1"),
          type: "error",
          offset: 100,
        });
        return false;
      } else if (res.data == 0) {
        this.$message({
          message: this.$t("AppManage.ApplicationLibrary.Error2"),
          type: "error",
          offset: 100,
        });
        return false;
      } else {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.show = true;
        this.keywords = "";
        this.getDatalist();
        this.id = "";
        this.ruleForm.id = "";
        this.ruleForm.name = "";
        this.ruleForm.version = "";
      }
    },
    // 单个删除
    del(id) {
      var parma = {
        ids: [id],
        deleteAll: 2,
      };
      this.$confirm(
        this.$t("AppManage.ApplicationLibrary.CheckDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/android/appLib/delete",
            parma,
            true
          );
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTable.clearSelection();
          this.page.currentPage = 1;
          this.getDatalist();
        })
        .catch(() => {
          return false;
        });
    },
    // 选中
    handleSelectionChange(val) {
      this.delarr = val;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 批量删除
    delMuch(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      });
      var params = {
        ids: ids,
        deleteAll: 2,
      };
      this.$confirm(
        this.$t("AppManage.ApplicationLibrary.CheckDel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(() => {
          this.$axios
            .post("/httpServe/android/appLib/delete", params, true)
            .then(() => {
              this.$message({
                message: this.$t("public.SuccessfullyDeleted"),
                type: "success",
                offset: 100,
              });
              this.$refs.multipleTable.clearSelection();
              this.page.currentPage = 1;
              this.getDatalist();
            })
            .catch(() => {
              return false;
            });
        })
        .catch(() => {
          return false;
        });
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.getDatalist();
    },
    // 响应分页组件查询事件
    handleParentGetLists(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDevName();
    },
    // 响应分页组件查询事件
    handleParentGetLists(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      // 调用查询方法
      this.getDevName()
    },
    // 点击安装设备详情
    DevDetail(val) {
      this.appname = val.name;
      this.appversion = val.versionName;
      this.ruleForm.id = val.identifier;
      this.deviceId = String(val.id);
      this.getDevName();
      this.statusVisible = true;
    },
    // 获取安装设备弹框数据
    async getDevName() {
      var parma = {
        id: this.deviceId,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        identifier: this.ruleForm.id,
        versionName: this.appversion,
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/selectDevice",
        parma,
        true
      );
      this.gridData = res.data.content;
      this.record = res.data.total;
    },
    // 安装设备弹框查询
    async searchDev() {
      if (this.input != "") {
        this.gridData = this.gridData.filter((item) => {
          return item.deviceName.includes(this.input);
        });
        this.record = this.gridData.length;
      } else {
        this.getDevName();
      }
    },
    // 弹框点击关闭事件
    handleClose() {
      this.visibaelFlag = false;
      this.statusVisible = false;
      this.input = "";
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false;
    },
    // 排序
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.prop == "lastUpdateTime") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "deviceCount") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "versionName") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "identifier") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      } else if (column.prop == "name") {
        if (column.order == "ascending") {
          this.orderRule = "ASC";
        } else {
          this.orderRule = "DESC";
        }
        this.orderColume = column.prop;
      }
      this.getDatalist();
    },
  },
};
</script>

<style lang="scss" scoped>
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 47px;
  left: 10px;
  z-index: 999;
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

// 处理按钮
.btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-right: 1px;
}

.el-input1 {
  width: 200px;
}

.el-button + .el-button {
  margin-left: 0px;
}

.el-form-item__label {
  font-size: 12px !important;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>